import {createContext, createElement, forwardRef, useCallback, useContext, useId} from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import {IconCheck, IconChevronDown, IconChevronUp, IconSelector, IconX} from '@tabler/icons-react';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ReactNode} from 'react';
import {dropdownMenuItemStyles} from '../dropdown-menu/styles';
import {FormControlContext} from '../form-control';
import IconButton from '../icon-button';
import Paper from '../paper';
import {textFieldInputStyles, textFieldStyles, type TextFieldVariantProps} from '../text-field/styles';
import type {Icon} from '../../types';
import type {PaperVariantProps} from '../paper/styles';

export type SelectContentProps = Omit<SelectPrimitive.SelectContentProps, 'asChild' | 'position'> &
	Omit<PaperVariantProps, 'shadow' | 'padding' | 'bordered'> & {
		portal?: boolean;
	};
const SelectContent = forwardRef<HTMLDivElement, SelectContentProps>(
	({children, className, portal = true, ...props}, forwardedRef) => {
		const content = (
			<SelectPrimitive.Content {...props} asChild position="popper">
				<Paper
					bordered
					className={twMerge(
						'relative h-fit max-h-[24rem] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
						className,
					)}
					padding="xs"
					ref={forwardedRef}
					shadow
				>
					<SelectPrimitive.ScrollUpButton asChild>
						<IconButton circular className="mx-auto shrink-0" icon={IconChevronUp} intent="ghost" size="sm" />
					</SelectPrimitive.ScrollUpButton>
					<SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
					<SelectPrimitive.ScrollDownButton asChild>
						<IconButton
							circular
							className="mx-auto shrink-0"
							icon={IconChevronDown}
							intent="ghost"
							size="sm"
						/>
					</SelectPrimitive.ScrollDownButton>
				</Paper>
			</SelectPrimitive.Content>
		);

		return portal ? <SelectPrimitive.Portal>{content}</SelectPrimitive.Portal> : content;
	},
);

export type SelectGroupProps = SelectPrimitive.SelectGroupProps;
const SelectGroup = SelectPrimitive.Group;

export type SelectLabelProps = SelectPrimitive.SelectLabelProps;
const SelectLabel = forwardRef<HTMLDivElement, SelectLabelProps>((props, forwardedRef) => (
	<SelectPrimitive.Label
		{...props}
		className="select-none px-1.5 py-0.5 text-left font-mono text-xs font-medium text-mauve11"
		ref={forwardedRef}
	/>
));

export type SelectSeparatorProps = SelectPrimitive.SelectSeparatorProps;
const SelectSeparator = forwardRef<HTMLDivElement, SelectSeparatorProps>((props, forwardedRef) => (
	<SelectPrimitive.Separator {...props} className="my-0.5 h-[1px] bg-mauve6" ref={forwardedRef} />
));

export type SelectItemProps = SelectPrimitive.SelectItemProps & {icon?: Icon};
const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
	({icon, children, className, ...props}, forwardedRef) => (
		<SelectPrimitive.Item
			{...props}
			className={twMerge(dropdownMenuItemStyles({destructive: false}), className)}
			ref={forwardedRef}
		>
			<div className="flex w-full items-center justify-start gap-1.5 truncate">
				{icon && createElement(icon, {className: 'h-4 w-4'})}
				<SelectPrimitive.ItemText asChild>
					<div className="flex w-full min-w-0 items-center justify-between truncate">{children} </div>
				</SelectPrimitive.ItemText>
			</div>
			<SelectPrimitive.ItemIndicator>
				<IconCheck className="h-4 w-4" />
			</SelectPrimitive.ItemIndicator>
		</SelectPrimitive.Item>
	),
);

const SelectContext = createContext<{
	value: SelectPrimitive.SelectProps['value'];
	onValueChange?: (value?: string) => void;
}>({
	value: undefined,
	onValueChange: undefined,
});

export type SelectTriggerProps = Omit<SelectPrimitive.SelectTriggerProps, 'prefix' | 'value'> &
	Omit<TextFieldVariantProps, 'textarea' | 'invalid'> & {
		clearable?: boolean;
		prefix?: ReactNode;
		placeholder?: string;
	};
const SelectTrigger = forwardRef<HTMLButtonElement, SelectTriggerProps>(
	(
		{
			clearable,
			prefix,
			size,
			subtle,
			hidden,
			disabled,
			readonly,
			placeholder,
			children,
			className,
			id,
			...props
		},
		forwardedRef,
	) => {
		const formControlProps = useContext(FormControlContext);
		const {value, onValueChange} = useContext(SelectContext);
		const defaultId = useId();

		return (
			<SelectPrimitive.Trigger
				{...props}
				aria-label={props['aria-label'] || 'Open select'}
				aria-labelledby={formControlProps.id}
				aria-readonly={!!readonly}
				className={twMerge(
					textFieldStyles({
						size,
						subtle,
						hidden,
						textarea: false,
						disabled,
						readonly,
						invalid: formControlProps.invalid,
					}),
					'group min-w-0',
					disabled || readonly ? 'pointer-events-none' : '',
					className,
				)}
				disabled={disabled}
				id={id || `${formControlProps.id || defaultId}-field`}
				ref={forwardedRef}
			>
				<div className="flex h-full w-full items-center gap-2">
					{prefix && <div className="flex shrink-0 flex-nowrap items-center">{prefix}</div>}
					<div
						className={twMerge(
							textFieldInputStyles({disabled, readonly}),
							'truncate text-left text-sm',
							value ? 'text-mauve12' : 'font-extralight text-mauve11',
						)}
					>
						<SelectPrimitive.Value className="block truncate" placeholder={placeholder}>
							{children || value || placeholder}
						</SelectPrimitive.Value>
					</div>
					{clearable && !readonly && !disabled && !!value && (
						<IconButton as="div" circular icon={IconX} onPress={() => onValueChange?.()} size="xs" />
					)}
					<SelectPrimitive.Icon>
						<IconSelector className="h-4 w-4" />
					</SelectPrimitive.Icon>
				</div>
			</SelectPrimitive.Trigger>
		);
	},
);

export type SelectProps = Omit<SelectPrimitive.SelectProps, 'onValueChange' | 'disabled'> & {
	value?: SelectPrimitive.SelectTriggerProps['value'];
	onValueChange?: (value?: string) => void;
};
const Select = assign(
	({value, onValueChange, children, ...props}: SelectProps) => {
		const handleValueChange = useCallback(
			(value?: string) => {
				if (value === '') return;
				onValueChange?.(value);
			},
			[onValueChange],
		);

		return (
			<SelectContext.Provider value={{value, onValueChange: handleValueChange}}>
				<SelectPrimitive.Root {...props} key={`${!!value}`} onValueChange={handleValueChange} value={value}>
					{children}
				</SelectPrimitive.Root>
			</SelectContext.Provider>
		);
	},
	{
		Trigger: SelectTrigger,
		Content: SelectContent,
		Item: SelectItem,
		Group: SelectGroup,
		Label: SelectLabel,
		Separator: SelectSeparator,
	},
);

Select.Content.displayName = 'Select.Content';
Select.Item.displayName = 'Select.Item';
Select.Group.displayName = 'Select.Group';
Select.Label.displayName = 'Select.Label';
Select.Separator.displayName = 'Select.Separator';

export default Select;
