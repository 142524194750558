import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {useTextField} from '@react-aria/textfield';
import {IconX} from '@tabler/icons-react';
import {twMerge} from 'tailwind-merge';

import type {AriaTextFieldOptions} from '@react-aria/textfield';
import type {AriaRole, ChangeEventHandler, ComponentPropsWithoutRef, ReactNode, RefObject} from 'react';
import {FormControlContext} from '../form-control';
import IconButton from '../icon-button';
import {textFieldInputStyles, textFieldStyles} from './styles';
import type {TextFieldVariantProps} from './styles';

export type TextFieldProps = {
	role?: AriaRole;
} & (Omit<
	AriaTextFieldOptions<'input' | 'textarea'>,
	| 'isDisabled'
	| 'isReadOnly'
	| 'isRequired'
	| 'inputElementType'
	| 'label'
	| 'id'
	| 'htmlFor'
	| 'errorMessage'
> &
	Omit<TextFieldVariantProps, 'textarea' | 'invalid'> & {
		className?: string;
		required?: boolean;
		clearable?: boolean;
		forceShowClearButton?: boolean;
	}) &
	(
		| {prefix?: ReactNode; suffix?: ReactNode; inputElementType?: 'input'}
		| ({inputElementType: 'textarea'; textLength?: string} & Pick<
				ComponentPropsWithoutRef<'textarea'>,
				'cols' | 'rows' | 'wrap'
		  >)
	);

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
	(
		{
			role,
			size,
			subtle,
			hidden,
			autoComplete = 'off',
			disabled,
			readonly,
			required,
			value,
			onChange,
			clearable,
			forceShowClearButton,
			className,
			...props
		},
		forwardedRef,
	) => {
		const formControlProps = useContext(FormControlContext);
		const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
		const {inputProps} = useTextField(
			{
				...props,
				isDisabled: !!disabled,
				isReadOnly: !!readonly,
				isRequired: required,
				value,
				onChange,
				'aria-label': formControlProps.label || props['aria-label'],
				'aria-labelledby': formControlProps.id || props['aria-labelledby'],
				id: `${formControlProps.id || props['aria-label'] || props['aria-labelledby']}-field`,
				label: formControlProps.label || props['aria-label'],
			},
			ref,
		);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

		return (
			<div
				className={twMerge(
					textFieldStyles({
						size,
						subtle,
						hidden,
						invalid: formControlProps.invalid,
						disabled,
						readonly,
						textarea: props.inputElementType === 'textarea',
					}),
					className,
				)}
			>
				{props.inputElementType !== 'textarea' && props.prefix && (
					<div className="flex shrink-0 flex-nowrap items-center">{props.prefix}</div>
				)}
				{role === 'none' ? (
					<div
						aria-label={formControlProps.label}
						className={twMerge(
							textFieldInputStyles({readonly, disabled}),
							value ? 'text-mauve12' : 'font-extralight text-mauve11',
						)}
						ref={ref as RefObject<HTMLInputElement>}
					>
						{value || props.placeholder}
					</div>
				) : props.inputElementType === 'textarea' ? (
					<textarea
						{...(inputProps as ComponentPropsWithoutRef<'textarea'>)}
						aria-label={formControlProps.label}
						autoComplete={autoComplete}
						className={textFieldInputStyles({readonly, disabled})}
						cols={props.cols}
						onChange={inputProps.onChange as ChangeEventHandler<HTMLTextAreaElement>}
						ref={ref as RefObject<HTMLTextAreaElement>}
						role={role}
						rows={props.rows}
						wrap={props.wrap}
					/>
				) : (
					<input
						{...(inputProps as ComponentPropsWithoutRef<'input'>)}
						aria-label={formControlProps.label}
						autoComplete={autoComplete}
						className={textFieldInputStyles({readonly, disabled})}
						ref={ref as RefObject<HTMLInputElement>}
						role={role}
					/>
				)}

				{clearable && ((!disabled && !readonly) || forceShowClearButton) && !!value && (
					<IconButton as="div" circular icon={IconX} onPress={() => onChange?.('')} size="xs" />
				)}
				{props.inputElementType !== 'textarea' && props.suffix && (
					<div className="flex shrink-0 flex-nowrap items-center">{props.suffix}</div>
				)}
			</div>
		);
	},
);

TextField.displayName = 'TextField';

export default TextField;

export * from './styles';
