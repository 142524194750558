export const QUERY_KEYS = {
	ActiveJobSearch: ['activeJobSearch'],
	ActiveOperation: ['activeOperation'],
	Companies: ['companies'],
	JobSearches: ['jobSearches'],
	Operations: ['operations'],
	Resumes: ['resumes'],
	Job: ['job'],
	Jobs: ['jobs'],
	UnfilteredJobs: ['unfilteredJobs'],
	Account: ['account'],
	UserPreferences: ['userPreferences'],
	Subscription: ['subscription'],
	Prices: ['prices'],
	PersonalPage: ['personalPage'],
	JobBoardJob: ['jobBoardJob'],
	JobBoardJobSummary: ['jobBoardJobSummary'],
	JobBoardJobSalaryInsights: ['jobBoardJobSalaryInsights'],
	JobBoardJobs: ['jobBoardJobs'],
	JobBoardJobsByCompany: ['jobBoardJobsByCompany'],
	JobBoardJobTypes: ['jobBoardJobTypes'],
	JobBoardCountries: ['jobBoardCountries'],
	JobBoardCities: ['jobBoardCities'],
	JobBoardCompany: ['jobBoardCompany'],
	JobBoardJobCompanyLayoffs: ['jobBoardJobCompanyLayoffs'],
	JobBoardCompanies: ['jobBoardCompanies'],
	JobBoardIndustries: ['jobBoardIndustries'],
	JobBoardRegions: ['jobBoardRegions'],
	DemoJobs: ['demoJobs'],
	DemoResumes: ['demoResumes'],
	DemoPersonalPage: ['demoPersonalPage'],
} as const;
